
/* -------------------------- Reset css ----------------------- */
*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b
u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead,
tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
  font-family: Inter;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  line-height: 1;
  font-size: 14px;
  color: #fff;
}

.app {
  background-image: url(/var/www/html/dlt_finance/src/imgs/bg_blue.jpg);
}

.overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 200px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

@keyframes backgroundScroll {
  from {background-position: left 0 top 0, left 0 top 0, left 0 top 0;}
  to {background-position: left 0 top -150px, left 0 top 0, left 0 top 150px;}
}

.wrapper {
  background-image:
    url(/var/www/html/dlt_finance/src/imgs/main_bg_horisontal.png),
    url(/var/www/html/dlt_finance/src/imgs/main_bg_dots.png),
    url(/var/www/html/dlt_finance/src/imgs/main_bg_vertical.png);
  background-repeat: no-repeat;
  animation: backgroundScroll 2s linear 1;
  background-position: left 0 top -150px, left 0 top 0, left 0 top 150px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/* -------------------------- Header ----------------------- */

._container {
  max-width: 1270px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: content-box;
}

._ibg {
  position: relative;
}

._ibg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.wrapper_custody > .header {
  color: black;
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
}

.wrapper_markets > .header {
  color: black;
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
}

.wrapper_custody > .header > .header_container {
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
}

.wrapper_markets > .header > .header_container {
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
}

.header {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  top: 0;
  z-index: 500;
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
  background: url(/var/www/html/dlt_finance/src/imgs/bg_blue.jpg) repeat-x scroll 0 0 transparent;
}

.header_container {
  margin-top: 25px;
  display: flex;
  min-height: 120px;
  align-items: center;
}

.header_logo {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
  text-decoration: none;
}

.header_logo_img {
  width: 100%;
}

.header_menu {
  font-size: 18px;
  margin: 0 0 0 150px;
}

.menu {
  padding: 5px 0;
}

@media (max-width: 992px) {
  .menu {
    margin: 0 0 0 100px;
  }
}

@media (max-width: 767px) {
  .menu {
    margin: 0 0 0 60px;
  }
  .header_logo_img {
    min-width: 180px;
  }
}

@media (max-width: 479px) {
  .menu {
    margin: 0 0 0 40px;
  }
}

.menu_list {
  flex-wrap: wrap;
  display: flex;
}

.menu_item {
  margin: 3px 10px;
}

.menu_link {
  text-decoration: none;
  color: inherit;
  line-height: calc(24/14*100%);
}

@media (max-width: 479px) {
  .menu_link {
    font-size: 18px;
  }
}

.menu_link:hover {
  opacity: 0.6;
}

/* -------------------------- Main ----------------------- */

.main {
  width: 100%;
  position: relative;
  top: 30px;
}

.main_body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.main_block_body {
  max-width: 500px;
}

.main_body_before {
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 400;
  line-height: calc(15/14*100%);
}

.main_body_title {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  padding-bottom: 20px;
}

.main_body_text {
  font-size: 18px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: calc(21/14*100%);
}

@media (max-width: 767px) {
  .main_block_list {
    width: 100%;
  }

  .main_block_list_item {
    text-align: center;
  }
}

.main_block_list_item {
  width: 100%;
  padding: 25px 0 25px 150px;
  font-size: 18px;
}

.main_block_list_icon {
  margin-right: 10px;
}


/* -------------------------- Custody Main ----------------------- */

.main_custody_body {
  display: flex;
  margin-bottom: 50px;
  min-height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(/var/www/html/dlt_finance/src/imgs/custody_body_bg.png);
}

.main_custody_block_body {
  width: 100%;
  position: relative;
  display: flex;
  z-index: 75;
}

.main_custody_body_container {
  margin-top: 70px;
  margin-left: 70px;
  z-index: 75;
}

@media (max-width: 767px) {
  .main_custody_body_container {
    margin-top: 50px;
    margin-left: 50px;
  }
}

@media (max-width: 479px) {
  .main_custody_body_container {
    margin-top: 25px;
    margin-left: 10px;
  }
}

.main_custody_body_wrap {
  max-width: 800px;
  padding-bottom: 35px;
}

.main_custody_body_before {
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

.main_custody_body_title {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  padding-bottom: 20px;
}

.main_custody_body_text {
  font-size: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: calc(21/14*100%);
}

.main_custody_body_button {
  margin-top: 25px;
}

@media (max-width: 992px) {
  .main_block_list_item {
    padding: 25px 0 25px 50px;
  }
}

/* -------------------------- Markets main ----------------------- */

.main_markets_body {
  display: flex;
  margin-bottom: 50px;
  min-height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(/var/www/html/dlt_finance/src/imgs/markets_main_bg.png);
}

.main_markets_body_container {
  margin-top: 70px;
  margin-left: 70px;
  z-index: 75;
}

.main_markets_body_wrap {
  max-width: 800px;
  padding-bottom: 35px;
}

.main_markets_body_before {
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

.main_markets_body_title {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  padding-bottom: 20px;
}

.main_markets_body_text {
  font-size: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: calc(21/14*100%);
}

.main_markets_body_button {
  margin-top: 25px;
}

@media (max-width: 767px) {
  .main_markets_body_container {
    margin-top: 50px;
    margin-left: 50px;
  }
}

@media (max-width: 479px) {
  .main_markets_body_container {
    margin-top: 25px;
    margin-left: 10px;
  }
}

/* -------------------------- Markets institutions ----------------------- */

.markets_institutions {
  color: #000;
  min-height: 400px;
}

.institutions_container {
  display: flex;
  flex-wrap: wrap;
}

.institutions_block {
  margin: 10px 20px;
  width: calc(33.3% - 40px);
}

.markets_benefits_lock img {
  width: 30px;
  height: 40px;
}

@media (max-width: 992px) {
  .institutions_block {
    width: calc(50% - 40px);
  }
}

@media (max-width: 767px) {
  .institutions_block {
    width: calc(100% - 40px);
  }
}

.institutions_block_title {
  margin: 25px 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.institutions_block_text {
  margin: 25px 10px;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-wrap;
  text-rendering: optimizelegibility;
  letter-spacing: 0px;
}

/* -------------------------- Markets Benefits ----------------------- */

.markets_benefits_body {
  display: flex;
  margin-bottom: 50px;
  min-height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(/var/www/html/dlt_finance/src/imgs/markets_main_bg.png);
}

.markets_benefits_body_container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-left: 70px;
  z-index: 75;
}

.markets_benefits_body_wrap {
  max-width: 500px;
  padding-bottom: 35px;
}

@media (max-width: 767px) {
  .markets_benefits_body_container {
    margin-top: 50px;
    margin-left: 50px;
  }
}

@media (max-width: 479px) {
  .markets_benefits_body_container {
    margin-top: 25px;
    margin-left: 10px;
  }
}

.markets_benefits_icons {
  min-width: 350px;
}
.markets_benefits_icon {
  min-height: 130px;
  display: flex;
  align-items: flex-start;
}

.markets_benefits_text_usability,
.markets_benefits_text_performance,
.markets_benefits_text_banking {
  display: none;
  padding: 0 0 5px 50px;
  font-size: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 20px;
}

.usability_hover:hover + .markets_benefits_text_usability {
  display: flex;
  height: 95px;
}

.performance_hover:hover + .markets_benefits_text_performance {
  display: flex;
  height: 95px;
}

.banking_hover:hover + .markets_benefits_text_banking {
  display: flex;
  height: 95px;
}

.banking_hover_text {
  display: block;
  height: 50px;
  padding: 10px 5px 10px 45px;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  white-space: pre-wrap;
  text-rendering: optimizelegibility;
}

.usability_hover:hover
+ .markets_benefits_text_usability
+ .banking_hover_text {
  display: none;
}

.performance_hover:hover
+ .markets_benefits_text_performance
+ .banking_hover_text {
  display: none;
}

.banking_hover:hover
+.markets_benefits_text_banking
+ .banking_hover_text {
  display: none;
}
/* -------------------------- Markets Plus ----------------------- */

.circle_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.circle_style {
  width: 50px;
	height: 50px;
	background: rgb(0,120,255);
  position: relative;
	border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.circle_style > span {
  position: absolute;
}

.pulse-ring {
  width: 50px;
  height: 50px;
  border: 3px solid rgb(0,120,255);
  border-radius: 50%;
  position: absolute;
  animation: pulsate infinite ease-in-out 2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-medium-circle {
  position: absolute;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  background: transparent;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.5), inset 2px 2px 4px rgba(0,0,0,.5);
  border: 2px solid rgb(0,120,255);
  animation: pulsate infinite ease-in-out 2s 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-big-circle {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background: transparent;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.5), inset 2px 2px 4px rgba(0,0,0,.5);
  border: 2px solid rgb(0,120,255);
  animation: pulsate infinite ease-in-out 2s 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-huge-circle {
  position: absolute;
  width: 95px;
  height: 95px;
  border-radius: 100%;
  background: transparent;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.5), inset 2px 2px 4px rgba(0,0,0,.5);
  border: 2px solid rgb(0,120,255);
  animation: pulsate infinite ease-in-out 2s 0.9s;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 0;
  }
  
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
  }
}

/* -------------------------- Markets API ----------------------- */

.markets_api_body {
  display: flex;
  margin-bottom: 25px;
  color: #000;
}

.markets_api_body_wrap {
  max-width: 500px;
  padding-bottom: 35px;
}

.markets_api_body_before {
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

.markets_api_body_title {
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  padding-bottom: 20px;
}

/* -------------------------- Markets Tabs ----------------------- */

.markets_tabs_body {
  color: #000;
}

.one_access_img > img {
  width: 100%;
}

.superior_products_img {
  position: relative;
}

.superior_products_img1 {
  position: absolute;
  width: 100%;
}

.superior_products_img2 {
  position: absolute;
  top: 160px;
  left: 240px;
  width: 60% !important;
}

.react-tabs {
  display: flex;
  width: 100%;
  height: 400px;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0;
  padding: 0;
}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #ebedf3;
  border-color: #1c1d1f;
  border-left: 4px solid rgb(0,120,255);
}

.react-tabs__tab-panel {
  display: none;
  width: 75%;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  min-height: 500px;
  width: 900px;
}

@media (max-width: 1240px) {
  .panel-content {
    width: 800px;
  }

  .superior_products_img2 {
    top: 160px;
    left: 200px;
  }
}

@media (max-width: 1100px) {
  .panel-content {
    width: 700px;
  }

  .superior_products_img2 {
    top: 130px;
  }
}

@media (max-width: 960px) {
  .panel-content {
    width: 600px;
  }

  .superior_products_img2 {
    left: 150px;
    top: 110px;
  }
}

@media (max-width: 820px) {
  .panel-content {
    width: 500px;
  }

  .superior_products_img2 {
    left: 100px;
    top: 75px;
  }
}

@media (max-width: 680px) {
  .panel-content {
    width: 400px;
  }

  .superior_products_img2 {
    left: 75px;
    top: 55px;
  }
}

@media (max-width: 540px) {
  .panel-content {
    width: 300px;
  }

  .superior_products_img2 {
    left: 50px;
    top: 35px;
  }
}

@media (max-width: 400px) {
  .panel-content {
    width: 200px;
  }
}

.panel-content > h2 {
  padding: 10px 100px;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  white-space: pre-wrap;
  text-rendering: optimizelegibility;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
}

.panel-content > .one_access_img > .one_acess_margin {
  margin-top: -75px;
}

.panel-content > .one_access_img > .counterparty_risk_margin {
  margin-top: -125px;
}

/* -------------------------- Custody security ----------------------- */

.main_custody_security {
  display: flex;
  flex-wrap: wrap;
  min-height: 450px;
  z-index: 60;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(/var/www/html/dlt_finance/src/imgs/custody_security_bg.jpg);
}

.main_custody_security_container {
  max-width: 500px;
  padding-left: 75px;
}

.main_custody_security_title {
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
  padding: 65px 0 20px 0;
}

.main_custody_security_text {
  font-size: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: calc(21/14*100%);
  padding-bottom: 50px;
}

.custody_security_button_border {
  border: 1px solid white;
  border-radius: 2px;
  padding: 10px 25px;
}

.main_custody_security_details {
  color: black;
}

.main_custody_security_details > img {
  width: 100%;
}

.main_custody_security_lock {
  padding: 25px 0 0 25px;
}

@media (max-width: 545px) {
  .main_custody_security_lock > img {
    width: 95%;
  }
}

@media (max-width: 479px) {
  .main_custody_security_lock > img {
    width: 85%;
  }
}

/* -------------------------- Custody benefits ----------------------- */

.main_custody_benefits {
  min-height: 450px;
}

.custody_benefits_title {
  max-width: 600px;
  margin: 30px 0;
  color: #000;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
}

.custody_benefits_cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  align-content: center;
  width: 100%;
}

.custody_benefits_card {
  min-width: 270px;
  background-image: url(/var/www/html/dlt_finance/src/imgs/benefits_bg.png);
  margin: 10px 20px;
  width: calc(25% - 40px);
  padding: 30px 0 30px 0;
}

@media (max-width: 1269px) {
  .custody_benefits_card {
    width: calc(33.3% - 40px);
  }
}

@media (max-width: 992px) {
  .custody_benefits_card {
    width: calc(50% - 40px);
  }
}

@media (max-width: 767px) {
  .custody_benefits_card {
    width: calc(100% - 40px);
  }
}

@media (max-width: 479px) {
  .menu {
    margin: 0 0 0 40px;
  }
}

.custody_benefits_card_icon {
  width: 100%;
  display: flex;
}

.custody_benefits_card_icon > img {
  margin: 0 auto;
  width: 25%;
}

.custody_benefits_card_text {
  margin: 30px auto;
  width: 50%;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  font-style: normal;
  font-weight: 600;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

/* -------------------------- Custdy Regulated ----------------------- */

.main_custody_regulated_body {
  display: flex;
  flex-wrap: wrap;
}

.custody_regulated_img {
  width: 50%;
}

.custody_regulated_img > img {
  width: 100%;
}

.custody_regulated_info {
  min-width: 400px;
  margin: 100px 75px 0 75px;
  width: calc(50% - 150px);
}

@media (max-width: 550px) {
  .custody_regulated_info {
    min-width: 350px;
    margin: 10px 35px 0 35px;
  }
}

@media (max-width: 460px) {
  .custody_regulated_info {
    min-width: 300px;
    margin: 10px 15px 0 15px;
  }
}

.custody_regulated_info_title {
  padding: 50px 0;
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  white-space: pre-wrap;
  color: rgba(161, 87, 255, 1);
  text-rendering: optimizelegibility;
}

.custody_regulated_info_text {
  color: #000;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  white-space: pre-wrap;
  text-rendering: optimizelegibility;
}

@media (max-width: 1179px) {
  .custody_regulated_img {
    width: 100%;
  }

  .custody_regulated_info {
    margin-top: 10px;
    width: calc(100% - 150px);
  }
}

/* -------------------------- Custody Ecosystem ----------------------- */

.main_custody_ecosystem_body {
  display: flex;
  flex-wrap: wrap;
}

.custody_ecosystem_info {
  min-width: 400px;
  color: #000;
  margin: 100px 75px 40px 75px;
  width: calc(50% - 150px);
}

.custody_ecosystem_img {
  width: 50%;
}

.custody_ecosystem_img > img {
  margin: 250px auto 0 auto;
  width: 100%;
}

@media (max-width: 1179px) {
  .custody_ecosystem_img > img {
    margin-top: 50px;
  }
  .custody_ecosystem_img {
    width: 100%;
  }

  .custody_ecosystem_info {
    width: calc(100% - 150px);
  }
}

@media (max-width: 550px) {
  .custody_ecosystem_info {
    min-width: 350px;
    margin: 10px 35px 40px 35px;
  }
}

@media (max-width: 460px) {
  .custody_ecosystem_info {
    min-width: 300px;
    margin: 10px 15px 40px 15px;
  }
}

.custody_ecosystem_info_title {
  padding: 50px 0;
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  white-space: pre-wrap;
  color: rgba(161, 87, 255, 1);
  text-rendering: optimizelegibility;
}

.custody_ecosystem_info_list {
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  white-space: pre-wrap;
  text-rendering: optimizelegibility;
}

.custody_ecosystem_list_item {
  list-style-type: disc;
}

/* -------------------------- Imprint ----------------------- */

.main_imprint_block_body {
  padding: 0 25px 25px 25px;
  color: rgba(15, 41, 61, 1);
}

.imprint_privacy_title {
  margin: 0 10px 25px 10px;
  font-size: 52px;
  line-height: 52px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  white-space: pre-wrap;
  text-rendering: optimizelegibility;
}

.imprint_privacy_address, .imprint_privacy_contact, .terms_text,
.imprint_privacy_text, .non_binding_text, .guarantee_text,
.risk_text, .restrictions_text, .third_party_text,
.use_website_text, .communication_text, .investments_text,
.privacy_policy_text, .privacy_policy_text_date, .controller_text,
.personal_data_text, .analytics_text, .adwords_text,
.linked_in_text, .twitter_text, .data_transfer_text,
.rights_text, .processing_text, .protection_text {
  margin: 25px 10px;
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-wrap;
  text-rendering: optimizelegibility;
  letter-spacing: 0px;
}

.imprint_privacy_address {
  max-width: 200px;
}

.imprint_privacy_contact {
  max-width: 300px;
}

.imprint_privacy_text {
  max-width: 800px;
}

.terms_title,
.privacy_policy_title {
  margin: 25px 10px;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.non_binding_title, .guarantee_title, .risk_title,
.restrictions_title, .third_party_title, .use_website_title,
.communication_title, .investments_title, .controller_title,
.personal_data_title, .analytics_title, .adwords_title,
.linked_in_title, .twitter_title, .twitter_title,
.data_transfer_title, .rights_title, .processing_title,
.protection_title {
  margin: 25px 10px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.privacy_policy_text_date {
  font-style: italic;
}

.personal_data_text > ul > li {
  margin-left: 20px;
  list-style-type: disc;
}

/* -------------------------- Solutions ----------------------- */

.solutions_body {
  margin-top: 100px;
  min-height: 125px;
  align-content: center;
}

.solutions_body_wrap {
  border: 1px solid white;
  border-radius: 15px;
  padding: 25px 0;
}

.solutions_body_headline {
  text-align: center;
  margin: 30px 0 25px 0;
  font-size: 22px;
}

.solutions_body_button {
  width: 100%;
  height: 50px;
  display: flex;
  align-content: center;
}

.solutions_body_button > * {
  margin: 0 auto;
  font-weight: 700;
}

.solutions_body_button_border {
  border: 1px solid white;
  border-radius: 2px;
  padding: 10px 25px;
}

/* -------------------------- Cards ----------------------- */

.cards_body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  margin-bottom: 120px;
}

.card_body {
  box-sizing: content-box;
  margin: 10px 20px;
  color: black;
  background-color: white;
  width: calc(33% - 40px);
}

.card_logo {
  min-height: 80px;
  padding: 10px 20px;
}

.card_logo > img {
  width: 120px;
}

@media (max-width: 992px) {
  .card_body {
    width: calc(50% - 40px);
  }
}

@media (max-width: 767px) {
  .card_body {
    width: calc(100% - 40px);
  }

  .card_sub_title {
    min-height: 50px;
  }

  .card_title {
    min-height: 100px;
  }

  .card_text {
    min-height: 150px;
  }

  .card_logo {
    padding: 0 20px;
  }

  .card_logo > img {
    width: 200px;
  }
}

.card_sub_title {
  min-height: 60px;
  font-size: 20px;
  padding: 10px 20px;
}

.card_sub_title_color_b {
  color: rgba(0, 182, 215, 1) !important;
}

.card_sub_title_color_c {
  color: rgba(161, 87, 255, 1) !important;
}

.card_sub_title_color_m {
  color: rgba(31, 107, 255, 1) !important;
}

.card_title {
  min-height: 125px;
  padding: 10px 20px;
  font-size: 34px;
}

.card_text {
  min-height: 190px;
  font-size: 16px;
  padding: 10px 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: calc(21/14*100%);
}

.card_button {
  min-height: 60px;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.card_button_border {
  color: #fff;
  border-radius: 2px;
  padding: 15px 30px;
}

.card_button_color_b {
  border: 1px solid rgba(0, 182, 215, 1);
  background-color: rgba(0, 182, 215, 1);
}

.card_button_color_c {
  border: 1px solid rgba(161, 87, 255, 1);
  background-color: rgba(161, 87, 255, 1);
}

.card_button_color_m {
  border: 1px solid rgba(31, 107, 255, 1  );
  background-color: rgba(31, 107, 255, 1);
}

/* -------------------------- Contact us ----------------------- */

.conatct_us {
  color: #000;
}

.contact_container {
  display: flex;
  flex-wrap: wrap;
}

.contact_form, .contact_bg {
  width: 50%;
}

@media (max-width: 829px) {
  .contact_form, .contact_bg {
    width: 100%;
  }
}

.contact_form {
  min-width: 400px;
  padding: 30px 30px 30px 30px ;
}

@media (max-width: 410px) {
  .contact_form {
    min-width: 300px;
  }
}

.contact_form_text {
  font-size: 32px;
  line-height: 32px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  padding-bottom: 30px;
}

.contact_bg img {
  width: 100%;
}

/* -------------------------- Footer ----------------------- */

.footer {
  min-height: 150px;
  color: inherit;
}

.wrapper_custody > .footer {
  margin-top: 100px;
  color: #000;
}

.wrapper_markets > .footer {
  margin-top: 100px;
  color: #000;
}

.footer_body {
  font-size: 22px;
  position: relative;
  align-content: center;
}

.footer_body > * {
  display: flex;
  align-self: center;
  align-content: center;
}

.main_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
